import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import http from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'

export default function useCoursesList() {
  // Use toast
  const toast = useToast()

  const refCourseListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'title',
      label: 'Наименование',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Тип',
      sortable: true,
    },
    {
      key: 'created_at',
      label: 'Дата создания',
      sortable: true,
    },
    {
      key: 'status',
      label: 'Статус',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Действие',
    },
  ]
  const perPage = ref(10)
  const totalCourses = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const typeFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refCourseListTable.value ? refCourseListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCourses.value,
    }
  })

  const refetchData = () => {
    refCourseListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, typeFilter, statusFilter], () => {
    refetchData()
  })

  const fetchCourses = (ctx, callback) => {
    http
      .post('https://edu.startupchoikhona.tj/backend/api/course/index', {
        search: searchQuery.value,
        length: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        type: typeFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const courses = response.data.data.items
        callback(courses)
        totalCourses.value = response.data.data.total_rows
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка выборки списка курсов',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteCourse = (idCourse) => {
    Swal.fire({
      title: 'Вы действительно хотите удалить этот курс?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Удалить',
      customClass: {
        cancelButton: 'btn btn-outline-danger ml-1',
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
      .then(result => {
        if (result.value && idCourse > 0) {
          http
            .post(`https://edu.startupchoikhona.tj/backend/api/course/delete/${idCourse}`, {})
            .then(response => {
              refetchData()
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Успешно удалено.',
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
            })
            .catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Ошибка при удалении курса',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Удаление курса отменено',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCourseTypeVariant = type => {
    if (type === 1) return 'warning'
    if (type === 2) return 'info'
    return 'primary'
  }

  const resolveCourseStatusVariant = status => {
    if (status === 2) return 'warning'
    if (status === 1) return 'success'
    if (status === 0) return 'secondary'
    return 'primary'
  }

  return {
    fetchCourses,
    deleteCourse,
    tableColumns,
    perPage,
    currentPage,
    totalCourses,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCourseListTable,

    resolveCourseTypeVariant,
    resolveCourseStatusVariant,
    refetchData,

    // Extra Filters
    typeFilter,
    statusFilter,
  }
}
