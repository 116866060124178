<template>

  <div>

    <!-- Filters -->
    <courses-list-filters
        :type-filter.sync="typeFilter"
        :status-filter.sync="statusFilter"
        :type-options="typeOptions"
        :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Показ</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>строка</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Поиск..."
              />
              <b-button
                  variant="primary"
                  :to="{ name: 'course-create-page-dashboard' }"
              >
                <span class="text-nowrap">Добавить</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refCourseListTable"
          class="position-relative"
          :items="fetchCourses"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Записи не найдены"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Course -->
        <template #cell(title)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="32"
                  :src="'https://edu.startupchoikhona.tj/backend/'+data.item.image"
                  :text="avatarText(data.item.title)"
                  :variant="`light-${resolveCourseTypeVariant(data.item.type)}`"
                  :to="{ name: 'course-details', params: { id: data.item.id } }"
              />
            </template>
            <b-link
                :to="{ name: 'course-details', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.title }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Type -->
        <template #cell(type)="data">
            <span class="align-text-top text-capitalize">{{ typeTranslate[data.item.type] }}</span>
        </template>

        <!-- Column: CreatedAt -->
        <template #cell(created_at)="data">
            <span class="align-text-top text-capitalize">{{ data.item.created_at }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${resolveCourseStatusVariant(data.item.status)}`"
              class="text-capitalize"
          >
            {{ statusTranslate[data.item.status] }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-link :to="{ name: 'course-details', params: { id: data.item.id } }">
            <feather-icon icon="EyeIcon" class="text-success mr-1 font-medium-5"/>
          </b-link>
          <b-link :to="{ name: 'course-update-page-dashboard', params: { id: data.item.id } }">
            <feather-icon icon="EditIcon" class="text-primary mr-1 font-medium-5"/>
          </b-link>
          <span @click="deleteCourse(data.item.id)">
            <feather-icon icon="Trash2Icon" class="text-danger mr-1 font-medium-5"/>
          </span>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Показано {{ dataMeta.from }} до {{ dataMeta.to }} из {{ dataMeta.of }} строк</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalCourses"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import CoursesListFilters from './CoursesListFilters.vue'
import useCoursesList from './useCoursesList'

export default {
  components: {
    CoursesListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {

    const typeOptions = [
      {
        label: 'Курс',
        value: '1',
      },
      {
        label: 'Тест',
        value: '2',
      },
    ]

    const typeTranslate = {
      1: 'Курс',
      2: 'Тест',
    }
    const statusTranslate = {
      0: 'Неактивный',
      1: 'Активный',
      2: 'В ожидание',
    }

    const statusOptions = [
      {
        label: 'В ожидание',
        value: '2',
      },
      {
        label: 'Активный',
        value: '1',
      },
      {
        label: 'Неактивный',
        value: '0',
      },
    ]

    const {
      fetchCourses,
      deleteCourse,
      tableColumns,
      perPage,
      currentPage,
      totalCourses,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCourseListTable,
      refetchData,

      // UI
      resolveCourseTypeVariant,
      resolveCourseTypeIcon,
      resolveCourseStatusVariant,

      // Extra Filters
      typeFilter,
      statusFilter,
    } = useCoursesList()

    return {

      fetchCourses,
      deleteCourse,
      tableColumns,
      perPage,
      currentPage,
      totalCourses,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCourseListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveCourseTypeVariant,
      resolveCourseTypeIcon,
      resolveCourseStatusVariant,

      typeOptions,
      typeTranslate,
      statusOptions,
      statusTranslate,

      // Extra Filters
      typeFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
